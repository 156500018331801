.dynamicV {
  display: flex;
  justify-content: space-evenly;
  color: white;
  margin-left: 10px;
}

.threshold,
.gamma {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 0px 0px 0px 0px;
  margin: 0px auto;
}

.val {
  width: 20px;
}

#thres {
  color: white;
}

#thrNum,
#gNum {
  width: 80px;
  padding-left: 5px;
}

.pop3_buttons {
  display: flex;
  justify-content: center;
}

#layer3Btn {
  text-align: center;
  width: 60%;
  border-radius: 10px 0px 0px 10px;
  height: 50px;
  font-size: 10px;
  margin-bottom: 5px;
  background-color: #f2f2f2;
  color: rgb(57, 56, 56);
  margin-right: 3%;
}

#layer3Btn2 {
  text-align: center;
  width: 17%;
  border-radius: 0px 10px 10px 0px;
  height: 50px;
  font-size: 10px;
  margin-bottom: 5px;
}

.popup0-menu {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  min-width: 640px;

  border-radius: 30px;
  padding-bottom: 20px;
  color: rgb(6, 6, 6);
}

.popup123-menu {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 450px;
  border-radius: 30px;
  padding-bottom: 20px;
  color: rgb(6, 6, 6);
}

.popup11-menu {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  padding: 20px 20px 0px 20px;
  color: rgb(6, 6, 6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popup33-menu {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 500px;
  border-radius: 30px;
  padding-bottom: 20px;
  color: rgb(6, 6, 6);
}

.txNum123 {
  color: rgb(255, 255, 255);
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.txNum23 {
  color: rgb(255, 255, 255);
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.count23 {
  margin-top: 10px;
}

.configsButtons {
  display: flex;
  justify-content: center;
}

.layer0buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

#layer0Btn1 {
  text-align: center;
  width: 80%;
  border-radius: 0px 10px 10px 0px;
  height: 50px;
  font-size: 10px;
  margin-bottom: 5px;
  background-color: #f2f2f2;
  color: rgb(57, 56, 56);
  margin-left: 1%;
}

#layer0Btn2 {
  text-align: center;
  width: 10%;
  border-radius: 10px 0px 0px 10px;
  height: 50px;
  font-size: 10px;
  margin-bottom: 5px;
}
