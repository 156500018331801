.popup_export_tx {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  color: rgb(242, 241, 241);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px 0px 30px;
}

.popup-import {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 30px;
  padding: 30px 30px 0px 30px;
  color: rgb(242, 241, 241);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#clos_tx_visual {
  text-align: center;
  width: 20%;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(61, 61, 61, 0.365);
  z-index: 10;
  margin-top: 10px;
  background-color: #f2f2f2 !important;
  color: rgb(0, 0, 0);
}

#clos_connect {
  padding: 7px;
  text-align: center;
  width: 20%;
  border-radius: 10px;
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  margin-top: 20px;
  color: rgb(244, 242, 242);
}

.recording_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#record_button {
  width: 200px;
  height: 50px;
  margin: 0px 0px;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.accordion_margin {
  margin-bottom: 10px;
}
