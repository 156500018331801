.popup-menu {
  display: none;
  background: rgb(28, 29, 36);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 500px;
  border-radius: 30px;
  padding: 20px 10px;
}

.btn-menu {
  padding: 30px 10px 0px 10px;
  color: rgb(19, 19, 19);
  background-color: rgb(28, 29, 36);
  text-align: center !important;
}

.btn-menu div {
  cursor: pointer;
  padding: 15px 0px;
  color: rgb(252, 252, 252);
  margin-left: 15%;
  text-align: center;
  background-color: rgb(249, 74, 10);
  width: 70%;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.menu_features {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.all-features {
  display: grid;
  grid-template-columns: auto auto;
}

.all-features2 {
  display: grid;
  grid-template-columns: auto auto auto;
}

.single-features {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 50px;

  margin: 10px 10px;
  color: rgb(255, 255, 255);
  padding: 0px 10px;

  background-color: rgb(47, 49, 63) !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  border-radius: 7px;
}

.allParts {
  height: 5%;
  text-align: center;
  margin: 5px auto;
  width: 60%;
}

.allParts2 {
  text-align: center;
  margin: 4px auto;
  width: 80%;
}

.btnTXs {
  display: inline-block;
  height: 5%;
  width: 90%;
  margin: 0px auto;
  text-decoration: none;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
}

.btnTXs:focus {
  outline: none;
}

.btnTXs:hover {
  color: red;
  background-color: rgb(218, 218, 218);
}

.labell {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.divs {
  display: inline-block;
  height: 20px;
}

.all_features_visual {
  display: grid;
  grid-template-columns: auto auto;
}

.all_features_visual2 {
  display: grid;
  grid-template-columns: auto auto auto;
}

.divs_visual {
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.divs_visual2 {
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: flex-end;
  align-items: center;
}

.feature_name {
  height: 20px;
  width: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.one_feature {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;

  margin: 10px 20px;
  color: rgb(255, 255, 255);
  padding: 0px 10px;

  background-color: rgb(47, 49, 63) !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  border-radius: 7px;
}
