.popupExtra {
  background: #dfdfdf;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;
  border-radius: 30px;
  padding-bottom: 20px;
  color: rgb(242, 241, 241);
}

.txIndication {
  text-align: center;
  width: 50%;
  margin-left: 25%;
  line-height: 15px;
  color: rgb(58, 58, 58);
}

.colorCode {
  width: 100%;
}

#closExtra {
  padding: 7px;

  margin-left: 40%;
  text-align: center;

  width: 20%;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(61, 61, 61, 0.365);
  z-index: 10;
  margin-top: 10px;
  background-color: #f2f2f2 !important;
  color: rgb(0, 0, 0);
}

#black {
  color: rgb(16, 15, 15);
  font-size: 16px;
}

.localFile {
  color: rgb(242, 242, 242) !important;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px auto;
}

.server {
  color: rgb(223, 223, 225) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainFrame {
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.options {
  width: 90%;
}

.main {
  background-color: rgb(47, 49, 63) !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.summary {
  background-color: rgb(47, 49, 63) !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.details {
  background-color: rgb(223, 223, 225) !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.serverSet {
  color: rgb(223, 223, 225) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#button-color {
  background: rgb(47, 49, 63);
  color: rgb(223, 223, 225) !important;
}
