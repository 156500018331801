.all_body {
  padding-right: 15px;
}

.l1 {
  margin-top: 20px;
}
.l2 {
  margin-top: 60px;
}
.l3 {
  margin-top: 100px;
}

.range-slider {
  width: 80%;
  text-align: center;
  margin: 0px 0px 0px 10%;
  position: relative;
}

#non-linear-slider {
  color: rgb(126, 125, 125);
}
