.header {
  margin-bottom: 40px;
}

.logo {
  width: 80%;
  padding: 2px 10px;
  background-color: #f2f2f2;
  color: rgb(57, 56, 56);
  border-radius: 0px 0px 30px 30px;
  margin-left: 10%;
  text-align: center;
}
.navbar {
  text-align: left;
  background-color: #f2f2f2;
  color: rgb(57, 56, 56);
  padding-left: 30px;
  height: 60px;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  display: inline-block;
  padding: 8px 7px;
  line-height: 45px;
}

.navbar Button {
  text-decoration: none;
  color: rgb(57, 56, 56);
}
