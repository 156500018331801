#inflow,
#layer2,
#layer3,
#layer4 {
  margin: auto 10px;
  overflow-y: hidden;
  border-radius: 10px;
  color: #777;
  background: #fff;
  margin-bottom: 50px;
  line-height: 22px;
}

#layer3,
#layer4 {
  background: rgb(255, 255, 255) !important;
}

#inflow {
  max-height: 622px;
  height: 800px;
}

#layer2 {
  max-height: 360px;
  height: 485px;
  background: #fff;
  font-size: 15px;
}

#layer3 {
  max-height: 320px;
  height: 440px;
  background: #fff;
  font-size: 14px;
}

#layer4 {
  max-height: 281px;
  height: 401px;
  background: #fff;
  font-size: 14px;
}

.layers {
  min-height: 322px;
  margin-bottom: 20px;
}

.dot {
  margin-left: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.dott {
  width: 380px;
  height: 300px;
}

.amount {
  background-color: rgb(28, 29, 36);
  color: rgb(244, 244, 244);
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 15px;
}

.info-menu {
  background-color: rgb(28, 29, 36);
  color: rgb(255, 255, 255);
  line-height: 30px;
  padding-right: 10%;
}

.info-menu:after {
  content: "";
  display: table;
  clear: both;
}

.left-col {
  float: left;
  width: 40%;
  text-align: right !important;
}

.right-col {
  float: left;
  width: 60%;
  text-align: center;
}

.right-col:after {
  content: "";
  display: table;
  clear: both;
}

.rl-col {
  float: left;
  width: 35%;
  text-align: center;
  padding-left: 15%;
}

.rr-col {
  float: left;
  width: 50%;
  text-align: center;
}

.topp {
  font-size: 15px;
  color: rgb(89, 114, 255);
  padding-top: 10px;
  padding-bottom: 10px;
}

.lBot {
  text-align: right !important;
}

#dx {
  background-color: rgb(66, 86, 238);
  margin-bottom: 10px;
}

#canc {
  background-color: rgb(121, 121, 125);
  margin-top: 10px;
}

.show-menu {
  display: block !important;
}

#normal {
  background-color: rgb(55, 55, 2);
  color: white;
  padding: 0 4px;
  margin-right: 2px;
}

.txs {
  color: rgb(164, 166, 172);
  margin: 3px 0px 5px 0px;
  font-size: 95%;
}

.middle {
  display: inline-block;
  width: 50%;
  height: 20px;
}
.middleType1,
.middleType2 {
  display: inline-block;
  width: 10%;
  height: 20px;
}

.middleAmount {
  display: inline-block;
  width: 80%;
  height: 20px;
}

.pic1 {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.parts {
  width: 100%;
  height: 20px;
  text-align: left;
}

#normal {
  float: left;
  width: 10px;

  height: 20px;
  text-align: center !important;
}
#addNormal {
  float: left;

  height: 20px;
  text-align: center !important;
  background-color: rgb(66, 86, 238);
  color: white;
  padding: 0 4px;
  margin-right: 2px;
}

#addFraud {
  float: left;

  height: 20px;
  text-align: center !important;
  background-color: rgb(249, 74, 10);
  color: white;
  padding: 0 4px;
  margin-right: 2px;
}
