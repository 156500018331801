#layer0Btn1,
#layer1Btn,
#layer3Btn {
  text-align: center;
  width: 80%;
  height: 50px;
  font-size: 10px;
  margin-bottom: 5px;
  background-color: #f2f2f2;
  color: rgb(57, 56, 56);
}

.boxx {
  position: relative;
}

#clos,
#clos1,
#clos2,
#clos3 {
  padding: 7px;

  margin-left: 0px;
  text-align: center;

  width: 20%;
  border-radius: 10px;
  margin-top: 15px;
  background-color: #f2f2f2 !important;
  color: rgb(0, 0, 0);
}

.lineC {
  cursor: pointer;
  padding: 7px;
  color: rgb(1, 5, 9);

  margin: 15px 0px 0px 4%;
  text-align: center;
  background-color: rgb(255, 255, 255);
  width: 90%;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(120, 120, 120, 0.4);
  z-index: 10;
}

.txNum {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  color: rgb(255, 255, 255);
  line-height: 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  background-color: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  padding-left: 0px;
  padding-top: 5px;
}

.allTxNum {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.fiterdTxNum {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.vall {
  width: 20%;
}

.popup1-menu,
.popup2-menu,
.popup3-menu {
  display: none;
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  border-radius: 30px;
  padding-bottom: 20px;
  color: rgb(6, 6, 6);
}

#clos0 {
  float: left;
  padding: 0px;

  width: 20px;
  border-radius: 10px;

  background-color: #e9e5e5 !important;
  color: rgb(31, 30, 30);
}

#clos0:hover {
  background-color: #e65555 !important;
  color: rgb(255, 255, 255);
}

#clossA {
  padding: 7px;

  margin-left: 5%;
  text-align: center;

  width: 20%;
  border-radius: 10px;

  margin-top: 15px;
  background-color: #f2f2f2 !important;
  color: rgb(0, 0, 0);
}

.senderConfig {
  width: 80%;
  margin-left: 10%;
}

.recieverConfig {
  width: 80%;
}
.configs {
  margin-bottom: 10px;
  width: 90%;
}

.cofigNames {
  color: white;
  text-align: right;
  padding-top: 35px;
}

.cNames h4 {
  line-height: 26px;
}
.cNameAmount {
  color: white;
  text-align: right;
}

.configAmount {
  width: 81%;
  margin: 10px 0px 0px 5%;
}
.configAmount2 {
  width: 100%;
}

.senderNam {
  color: white;
  margin-right: 10%;
  text-align: center;
}

.receiverNam {
  color: white;
  text-align: center;
  margin-right: 30%;
}

#inflowLayer1 img {
  height: 100%;
  width: 100%;
}

.slider {
  margin-left: 20%;
}

.one_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Change this line if you want. In this case it trimmed the text to 3 lines. */
  overflow: hidden;
}

#closss {
  margin-top: 15px;
  color: rgb(223, 223, 225);
}
