.popupTX_visual {
  background: #dfdfdf;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  color: rgb(242, 241, 241);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.popup_tutorial {
  background: #dfdfdf;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  color: rgb(242, 241, 241);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.help_img {
  width: 100%;
  border-radius: 30px;
}

.help_fado {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 30px;
}

#help_close {
  position: absolute;
  top: 6%;
  left: 97%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.tx_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.tx_title {
  color: rgb(38, 38, 38);
}

.all_tx_features {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgb(47, 49, 63);
  margin-bottom: 10px;
}

.tx_features {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.blur {
  opacity: 0.07;
}

#txVisual_close {
  margin-top: 15px;
}
