.popup-kpi {
  background: rgb(47, 49, 63);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 900px;
  border-radius: 30px;
  padding: 20px;
  color: rgb(242, 239, 239);
}

.kpi {
  margin: 5% 5%;
}

.kpiButtons {
  display: flex;
  justify-content: center;
}

#kpi_restart {
  width: 15%;
  border-radius: 7px;
  margin-right: 10px;
  margin-top: 15px;
  background-color: #f2f2f2 !important;
  color: rgb(0, 0, 0);
}

#kpi_close {
  width: 15%;
  border-radius: 7px;
  margin-right: 10px;
  margin-top: 15px;
  background-color: rgb(41, 39, 39) !important;
  color: rgb(213, 213, 213);
}

.lists {
  display: flex;
  justify-content: space-between;
}

.list {
  display: flex;
  width: 45%;
  align-items: center;
  flex-direction: column;
}

.list_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgb(41, 39, 39);
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;
  padding: 10px 20px;
  margin: 7px 0px;
}
